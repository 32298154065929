.thankyou .happyShopping {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 60px 30px;
	width: 40%;
	margin: 90px auto;
	text-align: center;
	background-color: #c5f9e8;
}
/* .thankyou .happyShopping .button-group a.btn {
	background: #8dc63f;
	font-size: 18px;
	padding: 8px 30px;
	color: #c5f9e8;
	font-weight: 600;
	box-shadow: none;
	border-radius: 50px;
} */
@media (max-width: 991px) {
	.thankyou .happyShopping {
		width: 100%;
	}
}
