/* About Css Start Here */
.about_sec {
  padding: 60px 0;
}
.about_sec .picture_box {
  position: relative;
}
.about_sec .picture_box .total_customer {
  background: #1aac7a;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  margin: 30px 30px;
}
.about_sec .picture_box .total_customer h2 {
  margin: 0;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 600;
  margin-right: 10px;
}
.about_sec .picture_box .total_customer h5 {
  margin: 0;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
  font-weight: 500;
}
.about_sec .content_wrapper .heading_wrapper h5.sub-title {
  margin: 0;
  color: #1aac7a;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: bold;
}
.about_sec .content_wrapper .heading_wrapper h2.title {
  color: #274d5e;
  font-weight: 800;
  font-size: 32px;
  line-height: 42px;
}
.about_sec .content_wrapper .heading_wrapper {
  padding-bottom: 15px;
}
.about_sec .content_wrapper .content p.grey {
  color: #7d7d7d;
  font-size: 14px;
  line-height: 24px;
}
.about_sec .content_wrapper .content p.greenish {
  color: #28463b;
  font-size: 14px;
  line-height: 24px;
}
.about_sec .content_wrapper .button-group {
  margin-top: 30px;
}
.about_sec .content_wrapper .button-group a.btn {
  padding: 10px 50px;
  border-radius: 5px;
}
/* About Css End Here */

/* Store Css Start Here */
.store_sec {
  padding: 60px 0 250px;
  background-image: url("../img/store-back.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.store_sec .heading_wrapper {
  text-align: center;
  padding-bottom: 40px;
}
.store_sec .heading_wrapper h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 18px;
}
.store_sec .heading_wrapper h3.title {
  font-size: 32px;
  font-weight: 500;
}
.store_sec .mile-box {
  display: flex;
  align-items: center;
  background: #0e845c;
  border: 1px solid #60ab91;
  padding: 15px 15px;
  margin: 0 7px;
  box-shadow: 0px 3px 3px 0px rgb(0 0 0 / 10%);
}
.store_sec .mile-box .content_box h5 {
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins";
}
.store_sec .mile-box .content_box h3 {
  font-size: 39px;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
}
.store_sec .mile-box .content_box h3 span.symbol {
  font-size: 23px;
  margin-left: 5px;
}
.store_sec .mile-box .icon_box figure {
  margin: 0;
}
.store_sec .mile-box .icon_box {
  margin-right: 15px;
}
/* Store Css Start Here */

/* Video Sec Start Here */
.video_sec {
  position: relative;
  margin-top: -13%;
}
.video_sec .ply-btn {
  position: absolute;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  animation: scale 1s infinite alternate;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/* Video Sec End Here */

/* FAQS Sec Css Start Here */
.faqs_sec {
  padding: 60px 0;
}
.faqs_sec .heading_wrapper {
  text-align: center;
  padding-bottom: 30px;
}
.faqs_sec .heading_wrapper h5.sub-title {
  margin: 0;
}
.faqs_sec .heading_wrapper h5.sub-title {
  color: #1aac7a;
  font-weight: 500;
  font-family: "Poppins";
}
.faqs_sec .heading_wrapper h3 {
  font-weight: 600;
  color: #274d5e;
  font-family: 'gtwb';
  font-size: 30px;
}
.faqs_sec .card {
  margin: 0 0 20px;
  border-radius: 0;
  background: transparent;
  /* box-shadow: 0px 1px 6px #ddd; */
  border: 0;
  box-shadow: 0px 3px 5px 1px rgb(0 0 0 / 10%);
}
.faqs_sec .card .card-header {
  border: 0;
  background: transparent;
  padding: 0;
}
.faqs_sec .card .card-header button.btn {
  background: transparent;
  border: 0;
  outline: none;
  color: #274d5e;
  font-size: 18px;
  padding: 20px 30px;
  width: 100%;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faqs_sec .card .card-header span {
  color: #274D5E;
  font-weight: bolder;
}
.faqs_sec .card .card-body {
  padding: 0px 20px 10px 20px;
  color: #274d5e;
  font-family: "GT Walsheim Pro black";
  font-weight: 100;
}
.faqs_sec .card .card-body p {
  padding-top: 20px;
  color: #274d5e;
  font-family: "GT Walsheim Pro black";
  font-weight: 100;
  margin: 0;
  border-top: 1px solid #274d5e47;
}
.faqs_sec .card .card-header h5 {
  /* border-bottom: 1px solid #274d5e47; */
  line-height: 0;
}
.faqs_sec .card .collapse.show {
  background: #f6fffc;
}
.faqs_sec .card .card-header button.btn.btn-link[aria-expanded="true"] {
  background: #f6fffc;
}
.faqs_sec .card .card-header button.btn i {
  background: #1aac7a;
  color: #fff;
  font-size: 13px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 33px;
}
.faqs_sec
  .card
  .card-header
  button.btn.btn-link[aria-expanded="true"]
  i:before {
  content: "\f068";
}
/* FAQS Sec Css End Here */
