.EmptyCart {
	padding: 40px 40px;
	text-align: center;
}
.EmptyCart .heading_wrapper {
	padding-bottom: 20px;
}
.EmptyCart .redirect a {
	font-family: "Poppins";
	font-weight: 600;
	border-radius: 5px;
	padding: 12px 30px;
	background: #1aac7a;
	color: #fff;
	text-decoration: none;
}
.LoginCartBtn {
	justify-content: flex-end !important;
}
.number input[type="text"] {
	width: 58px;
	text-align: center;
	border: 0px;
	color: #1aac7a;
	font-size: 20px;
	font-weight: bold;
	font-family: "GT Walsheim Pro regular";
	outline: none;
}
.number button.quantity {
	width: 38px;
	height: 31px;
	background: #1aac7a;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 21px;
	color: #fff;
	border: 2px solid #35b88b;
	cursor: pointer;
	outline: none;
}
.cart .number {
	display: flex;
	align-items: center;
	justify-content: center;
}
.cart {
	padding: 80px 0px;
}
.cart .table {
	border-bottom: 1px solid #d9e6dc;
}
.cart tr td {
	vertical-align: middle;
	font-family: "GT Walsheim Pro regular";
	color: #274d5e;
	font-size: 16px;
	font-weight: 500;
	border-top: 0px;
	padding: 30px 10px;
}
.cart .table-responsive tr {
	/* border-left: 1px solid #c4d8c9; */
}
.cart .table thead th:not(:last-child) {
	border-right: 1px solid #c4d8c9;
}
.cart .table thead th {
	/* border-right: 1px solid #c4d8c9; */
	/* border-top: 1px solid #c4d8c9; */
	border-bottom: 1px solid #c4d8c9;
	text-align: center;
	padding: 30px 0px;
	font-family: "GT Walsheim Pro regular";
	color: #274d5e;
	font-size: 16px;
	font-weight: 500;
}
.cart td:not(:last-child) {
	border-right: 1px solid #c4d8c9;
}
.cart td {
	/* border-left: 1px solid #c4d8c9; */
	/* border-right: 1px solid #c4d8c9; */
	text-align: center;
}

.cart td.pro-img img {
	background: #1aac7a;
	object-fit: cover;
	padding: 10px;
	border-radius: 9px;
	width: 60px;
	height: 60px;
}
.cart button {
	background: transparent;
	border: 0px;
	color: #1aac7a;
	font-size: 23px;
	padding: 0px;
	outline: none;
	cursor: pointer;
}
.cart .button-group button {
	background: #1aac7a;
	padding: 10px 20px;
	font-family: "GT Walsheim Pro black";
	color: #fff;
	font-size: 16px;
	margin-right: 18px;
}

.cart-table-wrapper {
	background: #fff;
	box-shadow: 3px 3px 10px 1px #00000024;
	padding-bottom: 30px;
	/* border-left: 1px solid #c4d8c9; */
	/* border-right: 1px solid #c4d8c9; */
	/* border-bottom: 1px solid #c4d8c9; */
}

.cart-content-wrapper ul {
	list-style: none;
}
.cart-content-wrapper ul li {
	display: flex;
	justify-content: space-between;
	line-height: 44px;
}
.cart-content-wrapper {
	padding: 30px;
}
.cart-footer-wrapper li {
	display: flex;
	justify-content: space-between;
}
.cart-footer-wrapper {
	padding: 20px;
	border-top: 1px solid #d9e6dc;
	border-bottom: 1px solid #d9e6dc;
}
.cart-content-wrapper ul {
	margin: 0px;
}
.cart-footer-wrapper ul {
	margin: 0px;
}
.cart-t-tal-wrapper .cart-header {
	padding: 20px 0px;
	border-top: 1px solid #d9e6dc;
	border-bottom: 1px solid #d9e6dc;
}
.cart-t-tal-wrapper {
	border-left: 1px solid #d9e6dc;
	border-right: 1px solid #d9e6dc;
	box-shadow: 3px 3px 10px 1px #00000024;
}
.cart-content-wrapper ul li span.property {
	font-family: "GT Walsheim Pro black";
	color: #274d5e;
	font-size: 18px;
	font-weight: 500;
}
.cart-content-wrapper ul li span.value {
	color: #617d8a;
	font-size: 18px;
	font-weight: 500;
	font-family: "GT Walsheim Pro black";
}

.cart-footer-wrapper ul li span.property {
	font-family: "GT Walsheim Pro black";
	color: #274d5e;
	font-size: 24px;
	font-weight: 500;
}

.cart-footer-wrapper ul li span.value {
	font-family: "GT Walsheim Pro black";
	color: #274d5e;
	font-size: 24px;
	font-weight: 500;
}

section.cart .btn.contine-shop {
	background: #dedede;
	color: #274d5e;
	font-family: "GT Walsheim Pro regular";
	font-size: 16px;
	font-weight: 500;
	border: 2px solid transparent;
	padding: 10px 20px;
}
section.cart .btn.pro-shop {
	background: #1aac7a;
	color: #fff;
	border: 2px solid transparent;
	font-family: "GT Walsheim Pro regular";
	font-size: 16px;
	font-weight: 500;
	border: 2px solid transparent;
	padding: 10px 20px;
}
