.register-user {
  padding: 80px 0px;
}
.text-on-img {
  position: absolute;
  bottom: 0px;
  padding: 2% 5% 2% 5%;
}
.social-icons ul li {
  list-style: none;
  display: flex;
  text-align: center;
}
.social-icons ul {
  padding: 0%;
}
.twitter,
.facebook,
.google {
  padding: 5%;
}
.get-connected span {
  background: #000;
  padding: 0 40px;
  position: relative;
}
.get-connected {
  position: relative;
}
.get-connected:before {
  content: "";
  background: #fff;
  width: 85%;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px auto;
  top: 12px;
}
.light-btn {
  background: #dedede;
}
.signup-form {
  padding: 12% 2% 0% 2%;
}
.register-user .form-group-input {
  position: relative;
}
.register-user .form-group-input button {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
.register-user .form-group-input button {
  border: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
}
.btn {
  color: #fff !important;
}
